<template>
  <div>
    <b-card no-body class="m-0">
      <!-- Table Top -->
      <b-row class="d-flex justify-content-between">
        <!-- Per Page -->
        <b-col class="mt-1 d-flex">
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :clearable="false"
            v-model="per_page"
            class="per-page-selector ml-1"
            style="width: 100px"
            :options="['5', '10', '20', '50', '100']"
            @input="changePagination()"
          />
        </b-col>
        <!-- Search -->
        <b-col class="mt-1 d-flex justify-content-end">
          <b-form-input
            placeholder="Search..."
            v-model="search"
            @input="handleSearch()"
            class="mr-1"
            style="width: 200px"
          />
        </b-col>
      </b-row>
      <div class="m-2">
        <section id="card-images" class="container1">
          <b-row
            md="12"
            style="align-item: center; justify-content: center; display: flex"
          >
            <b-table
              :busy="isLoading"
              ref="refUserListTable"
              class="position-relative"
              :items="exercise"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              @sort-changed="onSortChanged"
            >
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(id)="data">
                <span class="text-primary font-weight-bolder">
                  {{ data.index + 1 }}
                </span>
              </template>

              <template #row-details="data">
                <b-table
                  v-if="data.item.workout_maps && data.item.workout_maps.length > 0"
                  :items="data.item.workout_maps"
                  :fields="['No', 'name']"
                  thead-tr-class="d-none"
                >
                  <template #cell(No)="data">
                    <div style="max-width: 50px">Day {{ data.item.day }}</div>
                  </template>
                  <template #cell(name)="data">
                    <span v-if="data.item.dayoff"> Day Off </span>
                    <span v-else>
                      {{ data.item.main_exercise ? data.item.main_exercise.name : "" }} -
                      {{ data.item.sub_exercise ? data.item.sub_exercise.name : "" }}
                    </span>
                  </template>
                  <template #cell(comment)="data">
                    {{ data.item.sub_exercise ? data.item.sub_exercise.comment : "" }}
                  </template>
                </b-table>
              </template>
              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <span class="mr-1"> </span>
                  <span class="mr-1">
                    <span class="mr-1">
                      {{ data.item.name }}
                    </span>
                  </span>
                </div>
              </template>
              <template #cell(Action)="data">
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="EditIcon"
                    size="22"
                    class="cursor-pointer mr-1"
                    @click="handleEditForm(data.item)"
                  />
                  <feather-icon
                    icon="Trash2Icon"
                    size="22"
                    class="m-1"
                    @click="removeRow(data.item.id)"
                    style="cursor: pointer"
                  />
                  <feather-icon
                    icon="EyeIcon"
                    size="22"
                    class="m-1"
                    @click="data.toggleDetails"
                    style="cursor: pointer"
                  />
                </div>
              </template>
            </b-table>
            <div class="w-100 d-flex justify-content-between p-1">
              <!-- Pagination -->
              <div>
                <span class="text-nowrap">
                  Showing {{ paginationStart }} to {{ paginationEnd }} of
                  {{ paginationTotal }}</span
                >
              </div>
              <b-pagination
                :total-rows="paginationTotal"
                pills
                :per-page="per_page"
                v-model="paginationValue"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="changePagination()"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
            <b-col class="col-md-12 d-flex d-flex justify-content-end">
              <b-button variant="primary" @click="openForm()" class="mt-2"
                >Add Predesign Workout
              </b-button>
            </b-col>
          </b-row>
        </section>
        <div class="mt-5" v-if="addWorkout" id="nutritionForm">
          <Form
            :handleModal="handleModal"
            :ifEdit="ifEdit"
            :selectedId="selectedId"
            :selectedData="selectedData"
            :getExercise="getExercise"
          />
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
  BCard,
  BForm,
  BTable,
  BPagination,
  BFormFile,
  BFormInput,
  BFormGroup,
  BBadge,
  BSpinner,
  BFormTimepicker,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BMedia,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import axios from "@/component/axios";
import { required, integer } from "@validations";
import _ from "lodash";
import Form from "./Form.vue";

export default {
  components: {
    BMediaBody,
    BMediaAside,
    BAvatar,
    BMedia,
    BFormTimepicker,
    BRow,
    BCol,
    BPagination,
    vSelect,
    BCardText,
    BImg,
    BCardTitle,
    BButton,
    BCard,
    BForm,
    BTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    required,
    integer,
    Form,
  },
  data() {
    return {
      searchTerm: { name: "Title", value: "title" },
      videoSearch: "",
      videoSearchTerm: { name: "Title", value: "title" },
      tableColumns: [
        { key: "id", label: "No" },
        { key: "name", label: "Exercise", sortable: true },
        { key: "Action", label: "Actions" },
      ],
      isSortDirDesc: true,
      addWorkout: false,
      required: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      clickedRowData: null,
      isSideDataLoading: false,
      image: "",
      videoModal: false,
      exercise: [],
      id: 0,
      isLoading: false,
      selectedItem: {},
      ifEdit: false,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      paginationStart: 0,
      paginationEnd: 0,
      selectedId: null,
      selectedVideo: "",
      flag: false,
      allVideo: [],
      columnFilters: {},
      params: {},
      isLoadingCategory: false,
      exerciseVideo: [],
      search: "",
      Selected: null,
      selectedData: {},
    };
  },
  mounted() {
    this.getExercise();
  },
  methods: {
    handleSearch: _.debounce(function () {
      this.getExercise();
    }, 1000),
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      this.getExercise();
    },
    changePagination() {
      this.getExercise();
    },
    async handleEditForm(item) {
      this.ifEdit = true;
      this.selectedData = item;
      this.selectedId = item.id;
      this.addWorkout = true;
      setTimeout(() => {
        const formElement = this.$refs.myForm;
        if (formElement) {
          formElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    },
    async getExercise() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}pre-design-workouts/get`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
        // timeout: 1000,
      })
        .then((json) => {
          this.exercise = json?.data.data;
          this.paginationTotal = json?.data.total;
          this.paginationStart = json?.data.current_page;
          this.paginationEnd = json?.data.per_page;
          this.isLoading = false;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },

    async removeRow(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.value) {
            await axios({
              method: "Delete",
              url: `${this.baseApi}pre-design-workouts/delete/${id}`,
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.accessToken}`,
              },
            })
              .then(() => {
                const index = this.exercise.findIndex((item) => item.id === id);
                if (index !== -1) {
                  this.exercise.splice(index, 1);
                  this.$swal({
                    title: "Deleted",
                    text: "Client deleted successfully",
                    icon: "success",
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  });
                  this.getExercise();
                  this.$forceUpdate();
                }
              })
              .catch((error) => {
                console.error("Error deleting user:", error);
              });
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
    openForm() {
      this.ifEdit = false;
      this.selectedId = 0;
      this.addWorkout = true;
      setTimeout(() => {
        const formElement = this.$refs.myForm;
        if (formElement) {
          formElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
    },
    handleModal() {
      this.addWorkout = !this.addWorkout;
    },
  },
};
// .custom-no-head thead {
//   display: none;
// }
</script>
<style lang="scss">
.text-danger {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.selectVideo {
  height: 37px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  align-items: center;
  display: flex;
  padding-left: 10px;
  background-color: rgb(219, 218, 222);
  cursor: pointer;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.titleName {
  font-family: "Public Sans", sans-serif;
}
</style>
