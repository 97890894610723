<template>
  <div class="w-100">
    <b-card no-body class="p-2">
      <!-- Table Top -->
      <b-row class="d-flex justify-content-between w-100">
        <validation-observer ref="simpleRules" class="w-100">
          <b-col md="4" class="p-0">
            <b-form-group>
              <label for="input">Predesign Exercise Name</label>
              <validation-provider #default="{ errors }" rules="required" name="Exercise">
                <b-form-input
                  id="input"
                  maxlength="100"
                  v-model="name"
                  placeholder="Predesign Exercise Name"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-tabs pills v-model="activeTab">
            <b-tab
              v-for="(item, index) in payload_data"
              :key="index"
              :title="`Day ${index + 1}`"
              :active="index == activeTab"
            >
              <b-form ref="myForm">
                <b-row>
                  <b-col cols="12" class="mt-1">
                    <b-form-checkbox
                      v-model="item.dayOff"
                      class="mr-0"
                      name="is-rtl"
                      switch
                      inline
                      @input="handleDayOffChange"
                    >
                      <h4>Day Off</h4></b-form-checkbox
                    >
                  </b-col>
                </b-row>

                <b-row
                  v-for="(subItem, innerIndex) in item.main_exercise_array"
                  :key="innerIndex"
                  class="mt-1 main-exe"
                >
                  <template v-if="!item.dayOff">
                    <b-col cols="10" class="p-0">
                      <b-form-group>
                        <label for="input" style="font-family: Public Sans, sans sarif">
                          <span style="font-size: 15px">{{ innerIndex + 1 }}</span
                          >. Main Exercise</label
                        >
                        <v-select
                          v-model="subItem.main_exercise"
                          label="name"
                          :options="exerciseOptions"
                          @input="handleMainExercise($event, index, subItem, innerIndex)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <div>
                        <span v-if="item.main_exercise_array.length > 1">
                          <img
                            height="40px"
                            width="40px"
                            style="cursor: pointer"
                            class="mt-2"
                            src="../../../../../../public/cancelIcon.svg"
                            @click="
                              removeForm(item.main_exercise_array, innerIndex, index)
                            "
                            alt=""
                          />
                        </span>
                      </div>
                    </b-col>

                    <b-row
                      v-for="(subex, subexIn) in subItem.data"
                      :key="subexIn"
                      class="mt-1 w-100"
                    >
                      <b-col md="10">
                        <b-form-group>
                          <label for="input" style="font-family: Public Sans, sans sarif"
                            >Sub Exercise</label
                          >
                          <v-select
                            v-model="subex.sub_exercise"
                            label="name"
                            :options="subExerciseOptions[index][innerIndex]"
                            @input="handleSubExercise($event, subex)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <div>
                          <span>
                            <img
                              height="40px"
                              width="40px"
                              style="cursor: pointer"
                              class="mt-2 mr-1"
                              src="../../../../../../public/plusicon.svg"
                              @click="addNewForm(subItem.data, index)"
                              alt=""
                            />
                          </span>
                          <span v-if="subItem.data.length > 1">
                            <img
                              height="40px"
                              width="40px"
                              style="cursor: pointer"
                              class="mt-2"
                              src="../../../../../../public/cancelIcon.svg"
                              @click="removeForm(subItem.data, subexIn)"
                              alt=""
                            />
                          </span>
                        </div>
                      </b-col>
                      <b-col md="4" v-if="!subex.reps && !subex.sets">
                        <b-form-group>
                          <label for="input" style="font-family: Public Sans, sans sarif"
                            >Time (In minutes)</label
                          >
                          <b-form-input
                            id="input"
                            maxlength="10"
                            v-model="subex.time"
                            placeholder="time"
                            @input="handleChangeValue"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" v-if="!subex.time || subex.sets">
                        <b-form-group>
                          <label for="input" style="font-family: Public Sans, sans sarif"
                            >Sets</label
                          >
                          <b-form-input
                            id="input"
                            maxlength="10"
                            v-model="subex.sets"
                            placeholder="Sets"
                            @input="handleChangeValue"
                            type="number"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" v-if="!subex.time || subex.reps">
                        <b-form-group>
                          <label for="input" style="font-family: Public Sans, sans sarif"
                            >Reps</label
                          >
                          <b-form-input
                            id="input"
                            v-model="subex.reps"
                            maxlength="10"
                            type="number"
                            placeholder="Reps"
                            @input="handleChangeValue"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group>
                          <label for="input" style="font-family: Public Sans, sans sarif"
                            >Comments</label
                          >
                          <b-form-input
                            id="input"
                            v-model="subex.comment"
                            placeholder="Comment"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                </b-row>
                <b-col
                  md="12"
                  class="d-flex justify-content-between mt-2 gap-1 flex-wrap align-items-start"
                >
                  <div class="d-flex flex-wrap gap-1 order-2 order-md-1">
                    <b-button
                      variant="primary"
                      style="font-family: Public Sans, sans sarif"
                      class="mr-1"
                      :disabled="flag"
                      @click="handleNext(index)"
                    >
                      {{ index == 6 || ifEdit ? "Submit" : "Next" }}
                    </b-button>
                    <b-button
                      type="reset"
                      variant="primary"
                      style="font-family: Public Sans, sans sarif"
                      @click="Close()"
                    >
                      Cancel
                    </b-button>
                  </div>

                  <b-button
                    variant="primary"
                    v-if="!item.dayOff"
                    style="font-family: Public Sans, sans sarif"
                    class="mr-1 order-1 order-md-2"
                    @click="handleMain(index)"
                  >
                    Add Main Exercise
                  </b-button>
                </b-col>
              </b-form>
            </b-tab>
          </b-tabs>
        </validation-observer>
      </b-row>

      <b-modal hide-footer top no-close-on-backdrop size="lg" v-model="videoModal">
        <div>
          <div class="d-flex justify-content-center align-items-center flex-column">
            <h3 class="text-large">Select Video</h3>
            <h6 class="text-muted">Select a perticular Exercises Video.</h6>
          </div>
          <validation-observer ref="videoRules">
            <b-form @submit.prevent="submitForm">
              <b-row>
                <b-col md="12" class="mt-2">
                  <b-form-group>
                    <b-form-input
                      v-model="videoSearch"
                      placeholder="Search Video"
                      @input="handleVideoSearch()"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <h5>Select Video</h5>
                </b-col>

                <div
                  v-if="isLoadingCategory"
                  style="height: 200px"
                  class="w-100 d-flex justify-content-center align-items-center"
                >
                  <b-spinner label="Loading..."></b-spinner>
                </div>
                <div v-else class="w-100" style="max-height: 600px; overflow-y: auto">
                  <b-col
                    md="12"
                    class="mt-1"
                    v-for="(item, index) in exerciseVideo"
                    :key="index"
                  >
                    <div class="d-flex justify-content-between w-100">
                      <div>
                        <b-media no-body>
                          <b-media-aside>
                            <b-avatar rounded size="42" variant="light-danger">
                              <feather-icon size="18" icon="WindIcon" />
                            </b-avatar>
                          </b-media-aside>
                          <b-media-body>
                            <h6 class="transaction-title">
                              {{ item.title }}
                            </h6>
                            <!-- <small>Scales with any business</small> -->
                          </b-media-body>
                        </b-media>
                      </div>
                      <input
                        type="radio"
                        v-model="selectedVideo"
                        name="some-radios"
                        class="radio-right ml-5 cursor-pointer"
                        style="height: 20px; width: 20px"
                        :value="item"
                      />
                    </div>
                  </b-col>
                </div>
                <b-col md="12" class="d-flex mr-2 justify-content-end">
                  <b-button
                    variant="primary"
                    style="font-family: Public Sans, sans sarif"
                    class="mr-1"
                    @click="handleVideoSelect()"
                  >
                    {{ ifEdit == true ? "Update" : "Save" }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </div>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BImg,
  BButton,
  BCard,
  BForm,
  BTable,
  BPagination,
  BFormFile,
  BFormInput,
  BFormGroup,
  BBadge,
  BSpinner,
  BFormTimepicker,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BMedia,
  BTab,
  BFormCheckbox,
  BTabs,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import axios from "@/component/axios";
import { required, integer } from "@validations";
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    BMediaBody,
    BMediaAside,
    BAvatar,
    BMedia,
    BFormTimepicker,
    BRow,
    BCol,
    BPagination,
    vSelect,
    BCardText,
    BImg,
    BCardTitle,
    BButton,
    BCard,
    BForm,
    BTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    required,
    integer,
    BTab,
    BTabs,
    BFormCheckbox,
    flatPickr,
  },
  data() {
    return {
      videoSearch: "",
      name: "",
      activeTab: 0,
      videoSearchTerm: { name: "Title", value: "title" },
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      videoModal: false,
      exercise: [],
      id: 0,
      isLoading: false,
      selectedVideo: "",
      allVideo: [],
      params: {},
      exerciseVideo: [],
      payload_data: [],
      allVideo: [],
      isLoadingCategory: false,
      exerciseOptions: [],
      subExerciseOptions: {},
      predesignWorkout: [],
      flag: false,
    };
  },
  props: ["handleModal", "ifEdit", "selectedId", "selectedData", "getExercise"],
  watch: {
    selectedId() {
      this.handleEditedData();
    },
  },
  mounted() {
    // this.getVideo();
    this.getAllExercise();
    this.reset();
    if (this.ifEdit) {
      this.handleEditedData();
    }
  },
  methods: {
    handleMain(index) {
      this.payload_data[index].main_exercise_array.push({
        main_exercise: "",
        main_exercise_id: "",
        data: [
          {
            sub_exercise: "",
            sub_exercise_id: "",
            sets: "",
            reps: "",
            time: "",
            comment: "",
            // video: "",
          },
        ],
      });
      this.$forceUpdate();
    },
    handleChangeValue() {
      this.$forceUpdate();
    },
    reset() {
      for (let index = 0; index < 7; index++) {
        this.subExerciseOptions[index] = {};
        this.payload_data.push({
          dayOff: false,
          day: index + 1,
          main_exercise_array: [
            {
              main_exercise: "",
              main_exercise_id: "",
              data: [
                {
                  sub_exercise: "",
                  sub_exercise_id: "",
                  sets: "",
                  reps: "",
                  time: "",
                  comment: "",
                  // video: "",
                },
              ],
            },
          ],
        });
      }
    },
    handleDayOffChange() {
      this.$forceUpdate();
    },
    handleEditedData() {
      if (!this.selectedId) {
        this.reset();
        return;
      }
      // this.payload_data.map((item) => {
      //   this.payload_data[item.day - 1].data = [];
      //   item.dayOff = true;
      // });
      this.name = this.selectedData.name;
      let exeObj = {};
      let dayOff = {};
      this.selectedData.workout_maps.map((item, index) => {
        dayOff[item.day] = item.dayoff ? true : false;
        exeObj[item.day] = exeObj[item.day] ?? {};
        exeObj[item.day][item.main_exercise_id] = exeObj[item.day][
          item.main_exercise_id
        ] ?? {
          main_exercise: item.main_exercise,
          main_exercise_id: item.main_exercise_id,
          data: [],
        };
        this.subExerciseOptions[item.day - 1] =
          this.subExerciseOptions[item.day - 1] ?? [];
        this.subExerciseOptions[item.day - 1][
          Object.keys(exeObj[item.day]).length - 1
        ] = item.main_exercise ? item.main_exercise.sub_exercises : [];
        exeObj[item.day][item.main_exercise_id].data.push(item);

        // this.payload_data[item.day - 1].data.push(item);
      });
      for (let key in exeObj) {
        this.payload_data[parseInt(key) - 1] = {
          dayOff: dayOff[key],
          day: key,
          main_exercise_array: Object.values(exeObj[key]),
        };
      }
    },
    handleMainExercise(e, index, item, innerIndex) {
      if (e) {
        this.subExerciseOptions[index] = this.subExerciseOptions[index]
          ? this.subExerciseOptions[index]
          : {};
        this.subExerciseOptions[index][innerIndex] = this.subExerciseOptions[index][
          innerIndex
        ]
          ? this.subExerciseOptions[index][innerIndex]
          : [];
        this.subExerciseOptions[index][innerIndex] = e.sub_exercises;

        console.log(this.subExerciseOptions[index][innerIndex]);
        this.payload_data[index].main_exercise_array[innerIndex].main_exercise_id = e.id;
      } else {
        this.payload_data[index].main_exercise_array[innerIndex].main_exercise_id = "";
        item.data = [
          {
            sub_exercise: "",
            sub_exercise_id: "",
            sets: "",
            reps: "",
            time: null,
            comment: "",
            video: "",
          },
        ];
      }
      this.$forceUpdate();
    },
    handleSubExercise(e, item) {
      if (e) {
        item.sets = e.sets;
        item.reps = e.reps;
        item.time = parseInt(e.time);
        item.sub_exercise_id = e.id;
        item.comment = e.comment;
      } else {
        item.sets = "";
        item.reps = "";
        item.time = null;
        item.sub_exercise_id = "";
        item.comment = "";
      }
      // item.comment= item.comment;
      // item.sub_exercise_id= item.id;
    },
    async getAllExercise() {
      await axios({
        method: "GET",
        url: `${this.baseApi}exercise/get?page=1&per_page=100000`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          this.exerciseOptions = response.data.main_exercise.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleVideoSearch: _.debounce(function () {
      this.getSearchVideo();
    }, 1000),
    getSearchVideo() {
      let regex = new RegExp(this.videoSearch, "i");
      this.exerciseVideo = this.allVideo.filter((item) => {
        return regex.test(item.title);
      });
    },
    handleVideoSelect() {
      this.$refs.videoRules.validate().then(async (success) => {
        if (success) {
          if (this.selectedVideo) {
            this.selectedItem.video = this.selectedVideo;
            this.selectedItem.video_id = this.selectedVideo ? this.selectedVideo.id : "";
            this.videoModal = false;
          } else {
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Please fill all field",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    async getVideo() {
      this.isLoadingCategory = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}exercise/get-videos`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((response) => {
          this.allVideo = response.data.data;
          this.exerciseVideo = response.data.data;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoadingCategory = false;
        });
    },

    handleNext(index) {
      if (index == 6 || this.ifEdit) {
        this.$refs.simpleRules.validate().then((success) => {
          if (success == false) {
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Please fill in all fields",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (success) {
            this.handleForm();
          }
        });
      } else {
        this.activeTab = index + 1;
      }

      this.$forceUpdate();
    },
    handleForm() {
      this.flag = true;
      let max_day = 1;
      this.payload_data.map((item, index) => {
        item.main_exercise_array = item.main_exercise_array
          ? item.main_exercise_array.filter((inner) => inner.main_exercise_id)
          : [];
        item.main_exercise_array = item.main_exercise_array.filter((inner1) => {
          inner1.data = inner1.data
            ? inner1.data.filter((inner2) => inner2.sub_exercise_id)
            : [];
          return inner1.data.length > 0;
        });
        if (item.dayOff || item.main_exercise_array.length > 0) {
          max_day = index + 1;
        }
      });
      this.payload_data.map((item, index) => {
        if (index + 1 <= max_day && item.main_exercise_array == 0) {
          item.dayOff = true;
        }
      });
      let arr = this.payload_data.slice(0, max_day);
      let requestData = {
        name: this.name,
        data: arr,
      };
      const requestOptions = {
        method: `${this.ifEdit ? "put" : "post"}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: this.ifEdit
          ? `${this.baseApi}pre-design-workouts/update/${this.selectedId}`
          : `${this.baseApi}pre-design-workouts/add`,
        data: requestData,
      };
      axios(requestOptions)
        .then(() => {
          this.$swal({
            title: "Submitted",
            text: this.ifEdit ? "Updated Successfully" : "Added Successfully",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.getExercise();
          this.handleModal();
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with an error status code
            const errorDetails = error.response?.data?.errors;
            const errorMessage = errorDetails ? Object.values(errorDetails) : "";
            let message = "";
            errorMessage.map((item) => {
              let arr = item[0].split(".");
              let last = arr.pop();
              if (arr.length > 1 && !isNaN(arr[1])) {
                message += `In day ${arr[1] + 1} ${last ? last : arr.pop()} \n`;
              }
            });
            this.$swal({
              title: "Error!",
              html: message ? message : errorMessage,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          } else if (error.request) {
            // The request was made but no response was received
            console.error("No response received");
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error("Error setting up the request", error.message);
          }
        })
        .finally(() => {
          this.flag = false;
        });
    },
    openVideoModal(item) {
      this.selectedItem = item;
      this.videoModal = true;
      this.selectVideo = "";
      this.videoSearch = "";
      this.exerciseVideo = this.allVideo.slice();
    },
    Close() {
      this.handleModal();
    },
    addNewForm(subItem) {
      subItem.push({
        sub_exercise: "",
        sub_exercise_id: "",
        time: null,
        sets: "",
        reps: "",
        comment: "",
        // video: "",
      });
      this.$forceUpdate();
    },
    removeForm(subItem, index, day) {
      subItem.splice(index, 1);
      this.$forceUpdate();
      this.subExerciseOptions[day] = [];
      subItem.map((item) => {
        this.subExerciseOptions[day].push(item.main_exercise.sub_exercises);
      });
    },
  },
};
</script>
<style lang="scss" scope>
.text-danger {
  display: block;
  height: 5px;
}
.custom-no-head thead {
  display: none;
}
.selectVideo {
  height: 37px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  align-items: center;
  display: flex;
  padding-left: 10px;
  background-color: rgb(219, 218, 222);
  cursor: pointer;
}

@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.titleName {
  font-family: "Public Sans", sans-serif;
}
.gap-1 {
  gap: 10px;
}
.main-exe {
  border-bottom: 1px solid gray;
}
</style>

<!-- {
  name:'pre-1',
  data:[
    {
      main_exercise_id:1,
      day:1,
      data:[
        {
          sub_exercise_id:1,
          sets:2,
          reps:3,
          time:4,
        },

        {
          sub_exercise_id:3,
          sets:2,
          reps:3,
          time:4,
        }
      ]
    },
    {
      main_exercise_id:12,
      day:2,
      data:[
        {
          sub_exercise_id:12,
          sets:2,
          reps:3,
          time:4,
        },

        {
          sub_exercise_id:32,
          sets:2,
          reps:3,
          time:4,
        }
      ]
    },


  ]

} -->
